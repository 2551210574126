<script setup>
    import { inject, computed } from "vue";
    import { utils } from 'o365-utils';

    const currentSyncDefinition = inject('CurrentSyncDefinition');

    const currentSyncProgress = computed(() => {
        return currentSyncDefinition.value?.currentSyncProgress;
    });

    const currentSyncProgressPending = computed(() => {
        return currentSyncProgress.value?.currentSyncProgressPending ?? 0;
    });

    const currentSyncProgressSuccess = computed(() => {
        return currentSyncProgress.value?.currentSyncProgressSuccess ?? 0;
    });

    const currentSyncProgressError = computed(() => {
        return currentSyncProgress.value?.currentSyncProgressError ?? 0;
    });

    const progressStatusFilesToDownload = computed(() => {
        return currentSyncProgress.value?.progressStatusFilesToDownload ?? 0;
    });

    const progressStatusFilesCompleted = computed(() => {
        return currentSyncProgress.value?.progressStatusFilesCompleted ?? 0;
    });

    const progressStatusRecordsToDownload = computed(() => {
        return currentSyncProgress.value?.progressStatusRecordsToDownload ?? 0;
    });

    const progressStatusRecordsCompleted = computed(() => {
        return currentSyncProgress.value?.progressStatusRecordsCompleted ?? 0;
    });
</script>

<template>
    <div class="d-flex flex-column gap-2">
        <div class="d-flex flex-column text-muted gap-1" style="font-size:85%;">
            <div class="d-flex flex-column" :class="{ 'flex-column-reverse': progressStatusRecordsToDownload === 0 }">
                <div class="d-flex justify-content-between" :style="`visibility:${progressStatusFilesToDownload > 0 ? 'visible' : 'hidden'};`">
                    <span>
                        <i class="bi bi-file-earmark"></i>
                        Files
                    </span>

                    <span>
                        {{ utils.formatNumber(progressStatusFilesCompleted, '1 234') }} / {{ utils.formatNumber(progressStatusFilesToDownload, '1 234') }}
                    </span>
                </div>

                <div class="d-flex justify-content-between" :style="`visibility:${progressStatusRecordsToDownload > 0 ? 'visible' : 'hidden'};`">
                    <span>
                        <i class="bi bi-database"></i>
                        Records
                    </span>

                    <span>
                        {{ utils.formatNumber(progressStatusRecordsCompleted, '1 234') }} / {{ utils.formatNumber(progressStatusRecordsToDownload, '1 234') }}
                    </span>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <span>{{ $t('Total Progress') }}</span>

                <span>
                    {{ Math.round(currentSyncProgressSuccess) }} / 100%
                </span>
            </div>
        </div>

        <div class="d-flex align-items-center gap-2">
            <div class="progress-stacked w-100" style="height:15px;">
                <div class="progress" role="progressbar" :aria-valuenow="currentSyncProgressSuccess" aria-valuemin="0"
                    aria-valuemax="100" :style="{ width: `${currentSyncProgressSuccess}%` }">
                    <div class="progress-bar bg-success"></div>
                </div>

                <div class="progress" role="progressbar" :aria-valuenow="currentSyncProgressError" aria-valuemin="0"
                    aria-valuemax="100" :style="{ width: `${currentSyncProgressError}%` }">
                    <div class="progress-bar bg-danger"></div>
                </div>

                <div class="progress" role="progressbar" :aria-valuenow="currentSyncProgressPending" aria-valuemin="0"
                    aria-valuemax="100" :style="{ width: `${currentSyncProgressPending}%` }">
                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary"></div>
                </div>
            </div>

            <i v-if="currentSyncProgressError > 0"
                class="bi bi-exclamation-circle-fill text-danger"
                :title="$t('Expand the dialog to see more')">
            </i>
        </div>
    </div>
</template>

<style scoped>
    .progress-bar {
        transition: width 1s ease-in-out;
    }
</style>
